import React from "react"
import gitLogo from "../images/GitHub.png"

const ProjectsBody = ({ projects }) => (
  <div className="projectBody">
        {projects.map((array, index) => {
            return(
                <section key={index} className="projects">
                  <h4 className="projects">
                    {(array.link) ? 
                    <a className="projects" href={array.link} title="View Project">
                      {array.title}
                    </a>                      
                    : array.title}
                  </h4>
                  <p className="projects">{array.description}</p>
                  <a href={array.repo} title="View on GitHub"> 
                  <img className="projects" src={gitLogo} alt=""/>
                  </a>
            </section>
        )})}
  </div>
)


ProjectsBody.defaultProps = {
  projects: [{
    name:"",
    link:"http://",
    description:"",
    github:"https://github.com/",
    tags:[]
  }],
}

export default ProjectsBody
