import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import ProjectsBody from "../components/projectsBody"

const projects=[
  {
    title:"jpdk.me",
    description:"A home for misc projects",
    link:"https://jpdk.me",
    tags:["Gatsby", "JavaScript", "HTML", "CSS"],
    repo:"https://github.com/WitheredHope/jpdk.me"
  },
  {
    title:"Tunes For Tuesday",
    description:"A better collaborative playlist experience",
    link:"http://tft.jpdk.me",
    tags:["React", "Node.js", "JavaScript", "API", "Spotify"],
    repo:"https://github.com/WitheredHope/Tunes-For-Tuesday"
  },
  {
    title:"EnviroPlus Webserver",
    description:"A pi-powered webserver to display Environmental Data",
    tags:["Flask", "Python", "Raspberry Pi"],
    repo:"https://github.com/WitheredHope/EnviroPlus"
  }
]

const IndexPage = () => (
  <Layout>
    <SEO title="Home" keywords={[`gatsby`, `application`, `react`]} />
    <h1 className="page">Projects</h1>
      <ProjectsBody projects={projects}/>
  </Layout>
)

export default IndexPage
