import PropTypes from "prop-types"
import React from "react"


const Footer = ({ siteAuthor }) => (
  <div>
  <style dangerouslySetInnerHTML={{__html: `
    #footerLink:link {
      color: #4f4d47;
    }
    #footerLink:visited {
      color: #4f4d47;
    }
  `}} />
  <footer
    style={{
      
    }}
  >
    Built by <a id="footerLink" href="https://github.com/WitheredHope">{siteAuthor}</a>
  </footer>
  </div>
)

Footer.propTypes = {
  siteTitle: PropTypes.string,
}

Footer.defaultProps = {
  siteAuthor: ``,
}

export default Footer
