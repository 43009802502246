import React from "react"
import {Link} from "gatsby"

const Navbar = () =>(
    <div className="navbar">
        <Link to="/">
        <button className="navbar" id="navbar"><b>Home</b></button>
        </Link>
        <Link to="/about">
        <button className="navbar" id="navbar"><b>About</b></button>
        </Link>
        <Link to="/projects">
        <button className="navbar" id="navbar"><b>Projects</b></button>
        </Link>
        <Link to="/contact">
        <button className="navbar" id="navbar"><b>Contact</b></button>
        </Link>            
    </div>
)


export default Navbar